import React from "react"
import Layout from "../chunks/layout";
import {Helmet} from "react-helmet";
import Main from "../components/main"

export default function RefoundPolicy({location}) {
    return <Layout>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Checkout | Boostik</title>
            <link rel="canonical" href="http://boostik.io/"/>
            <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
        <Main title="Refund Policy" viewName="home" />
    </Layout>
}
